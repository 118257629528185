import React from "react"
import Layout from "../components/Layout"
import { MapWrapper, StaemmeWrapper, Stamm } from "../components/staemme/styled"
import GabMap from "../components/Map"

// content export
export default function MemberPage({ data, location }) {
  return (
    <Layout
      title="Gau Alt-Burgund | Stämme und Siedlungen"
      description="Informationen über die Stämme und Siedlungen im Gau Alt-Burgund"
      location={location}
    >
      <MapWrapper>
        <GabMap />
      </MapWrapper>

      <StaemmeWrapper>
        <Stamm align={"flex-start"}>
          <h2>Stamm Cassiopeia</h2>
          <a href={"http://www.stamm-cassiopeia.com"}>Website</a>
          <p>
            "Wir sind begeisterte und aktive Pfadfinder aus Wörrstadt. Mit
            unserer lokalen evangelischen Kirchengemeinde arbeiten wir oft
            zusammen. Mit Sippen in verschiedenen Altersgruppen fahren wir
            natürlich auch immer mit auf Lager. Auch das Wandern und Hajken ist
            genau unser Ding. Jeder ist natürlich immer herzlich willkommen!"
          </p>
        </Stamm>
        <Stamm align={"flex-end"} textAlign={"end"}>
          <h2>Stamm Colugo</h2>
        </Stamm>
        <Stamm align={"flex-start"}>
          <h2>Stamm Dietrich Bonhoeffer</h2>
          <p>
            Als Bockenheimer Pfadfinderstamm Dietrich-Bonhoeffer sind wir einer
            von 3 aktiven P(f)älzer Stämmen im Gau Alt-Burgund. Neben dem
            regelmäßigen Besuch regionaler, nationaler und internationaler
            Pfadilager, treffen wir uns natürlich auch einmal die Woche zur
            Sippenstunde. Unser Sippenheim liegt im alten Keller der Feuerwehr
            unter dem roten Rathaus (Leininger Ring 51, 67278 Bockenheim).
            Kontakt: Thibaut{" "}
            <a href={"mailto:t.a.koehn@t-online.de"}>t.a.koehn@t-online.de</a>
          </p>
        </Stamm>
        <Stamm align={"flex-end"} textAlign={"end"}>
          <h2>Stamm Grafen von Eberstein</h2>
          <a href={"https://www.grafen-von-eberstein.de/"}>Website</a>
        </Stamm>
        <Stamm align={"flex-start"}>
          <h2>Stamm Johannes Gutenberg</h2>
          <a href={"http://vcp-mainz.de/"}>Website</a>
          <p>
            Wir haben den VCP in unsere Landeshauptstadt gebracht! Unser Stamm
            hat ein aktives Wölflingsrudel und zwei Sippen, die sich wöchentlich
            zu Sippenstunden in Mainz-Gonsenheim treffen. Wir gehen gerne auf
            Fahrt und Lager und wachsen als jüngster Stamm im Gau fröhlich
            weiter. Kontakt:{" "}
            <a href={"mailto:louise@vcp-mainz.de"}>louise@vcp-mainz.de</a>
          </p>
        </Stamm>
        <Stamm align={"flex-end"} textAlign={"end"}>
          <h2>Stamm Leiningen</h2>
          <a href={"http://stamm.leiningen.de/"}>Website</a>
          <p>
            Wir, der Stamm Leiningen, sind eine Gruppe von ca. 40 aktiven
            Mitglieder in Bobenheim am Berg. Der Stamm wurde 1980 gegründet und
            bietet spannende Möglichkeiten für Gruppenstunden und
            Lagerfeuererlebnisse. Aktuell bieten wir für die Jahrgänge 2010-2012
            (Wölflinge), 2007-2010 (Jungpfadfinder*innen), 2003-2006
            (Pfadfinder*innen) Gruppenstunden an und freuen uns stets über neue
            Mitglieder. Die Gruppenstunden und einige Stammesaktionen finden
            dabei auf unserem eigenen Zeltplatz statt. Daher freuen wir uns
            ebenfalls über naturbegeisterte, ältere Pfadfinder*innen, die einen
            Anschluss in der Region suchen und Freude an der
            Pfadfinderatmosphäre haben und oder auf der Suche nach einem neuen,
            vielfältigen und spannenden Hobby sind.
          </p>
          <p>
            Bei Interesse freuen wir uns über die Kontaktaufnahme per Mail an{" "}
            <a href={"mailto:stammesfuehrer@leiningen.de"}>
              stammesfuehrer@leiningen.de
            </a>{" "}
            oder durch eine Nachricht auf{" "}
            <a href={"www.facebook.com/leiningen"}>
              www.facebook.com/leiningen
            </a>
          </p>
        </Stamm>
        <Stamm align={"flex-start"}>
          <h2>Stamm Lohengrin</h2>
          <a href={"https://www.stamm-lohengrin.de//"}>Website</a>
          <p>
            Der Stamm Lohengrin wurde 1983 gegründet und hat zur Zeit circa 65
            aktive Mitglieder. Wir haben eine Sippe und 6 Horten und arbeiten in
            Nieder-Olm eng mit der evangelischen Kirchengemeinde zusammen.
          </p>
        </Stamm>
        <Stamm align={"flex-end"} textAlign={"end"}>
          <h2>Siedlung Mainz Drais-Lerchenberg</h2>
          <p></p>
        </Stamm>
        <Stamm align={"flex-start"} textAlign={"end"}>
          <h2>Stamm Nibelungen</h2>
          <a href={"https://de-de.facebook.com/NiluVCP/"}>Website</a>
          <p>
            Als ältester Stamm im Gau gibt es bei uns bereits seit über 90
            Jahren Pfadfinderarbeit. Wir sind eine bunte Gruppe mit einem großen
            Zusammenhalt und viel Spaß, was wir bei unseren Gruppenstunden im
            Pfiffligheimer Caritasgarten oder auf Pfadfinderlagern leben. Wir
            freuen uns immer über neue Gesichter in jedem Alter, um weiter zu
            wachsen und auch zukünftig spannende Abeuteuer zu erleben. Kontakt:{" "}
            <a href={"mailto:sarah-mabrouk@web.de"}>sarah-mabrouk@web.de</a>
          </p>
        </Stamm>
        <Stamm align={"flex-end"}>
          <h2>Siedlung Parzival</h2>
          <p>
            Wir sind begeisterte Pfadfinder und Pfadfinderinnen aus Harxheim/
            Gau-Bischofsheim! Unsere Siedlung besteht aus 4 aktiven Sippen, die
            sich einmal wöchentlich für ihre Sippenstunde in dem Evangelischen
            Gemeindehaus in Harxheim treffen. Wir gehen gerne auf Lager und sind
            eine bunt gemischte Gruppe, die für alles offen ist! Kontakt bei
            Interesse:{" "}
          </p>
          <a href={"mailto:carla.reis724@gmail.com"}>
            carla.reis724@gmail.com
          </a>
          <a href={"mailto:johann-zoerntlein@t-online.de"}>johann-zoerntlein@t-online.de</a>
        </Stamm>
      </StaemmeWrapper>
    </Layout>
  )
}
