import styled from 'styled-components';
import dimensions from '../../constants/dimensions';
import colors from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';

export const MapWrapper = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const StaemmeWrapper = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${dimensions.maxWidth}px;
`;

export const Stamm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${dimensions.maxWidth};
  margin: 0 2rem 0 2rem;
  h2 {
    margin: 0;
  }
  a {
    color: ${colors.vcp};
    text-decoration: none;
    ::visited {
      color: ${colors.vcp};
    }
  }
  p {
    margin: 0;
    width: 50%;
    text-align: ${props => props.textAlign};
  }
  align-items: ${props => props.align};

  @media (max-width: ${breakpoints.medium}px) {
    align-items: flex-start;
    margin: 2rem;
    p {
      text-align: left;
      width: 80%;
    }
  }
`;
