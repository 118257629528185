import React, { Component, Fragment } from "react"
import breakpoints from "../../constants/breakpoints"
import styled from "styled-components"
// import "../../assets/leaflet/leaflet.css"
// import "../../assets/leaflet/markerIcon"

// this div contains the map
export const MapDiv = styled.div`
  height: 500px;
  width: 90vw;
  @media (min-width: ${breakpoints.large}px) {
    height: 400px;
    width: 1024px;
  }
`

// render the map
export default class GabMap extends Component {
  // LEAVE in cDM - Has to be executed after render
  componentDidMount() {
    if (typeof window !== `undefined`) {
      const L = require("leaflet")
      //      markerIcon = require("../../assets/leaflet/markerIcon")

      const myMap = L.map("map", {
        center: [49.7465, 8.115183], // center = Alzey
        zoom: 9,
      })
      // other tile layers: streets-v10, sattelite, ... replace in URL
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/mapbox/outdoors-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibGhvYyIsImEiOiJjanFtejFxcWI0cmVpNDNqd3l4djZqM3Y2In0.6VKppk4HiyvBr9SbyjW_uQ",
        {
          maxZoom: 18,
          // attribution has (also legally) to be given
          attribution:
            'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
            '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="http://mapbox.com">Mapbox</a>',
        }
      ).addTo(myMap)

      // marker icons seem not to work without including CSS file via url. So here they are:

      // create markers and add to map
      const colugo = L.marker([49.592495654795215, 8.203871548175812], {
        //icon: markerIcon,
      }).addTo(myMap)
      const jogu = L.marker([50.004759, 8.209164], {
        // icon: markerIcon,
      }).addTo(myMap)
      const gve = L.marker([49.606861, 8.313519], {
        // icon: markerIcon,
      }).addTo(myMap)
      const lohengrin = L.marker([49.904881, 8.202925], {
        // icon: markerIcon,
      }).addTo(myMap)
      const leiningen = L.marker([49.52242, 8.153904], {
        // icon: markerIcon,
      }).addTo(myMap)
      const parzival = L.marker([49.904492, 8.269119], {
        // icon: markerIcon,
      }).addTo(myMap)
      const cassiopeia = L.marker([49.842797, 8.115701], {
        // icon: markerIcon,
      }).addTo(myMap)
      const lutra = L.marker([49.439826, 7.748575], {
        //icon: markerIcon
      }).addTo(myMap)
      const dibo = L.marker([49.60626, 8.183235], {
        //  icon: markerIcon
      }).addTo(myMap)
      const sindilo = L.marker([49.442861, 7.896217], {
        // icon: markerIcon
      }).addTo(myMap)
      const nibelungen = L.marker([49.632875, 8.352296], {
        //icon: markerIcon,
      }).addTo(myMap)
      const mainzDraisLerchenberg = L.marker(
        [49.95744853578069, 8.19541218705121],
        {
          //icon: markerIcon,
        }
      ).addTo(myMap)

      // create and bind popups to map
      colugo.bindPopup("Stamm Colugo, Obrigheim")
      jogu.bindPopup("Stamm Johannes Gutenberg, Mainz")
      gve.bindPopup("Stamm Grafen von Eberstein, Worms-Horchheim")
      lohengrin.bindPopup("Stamm Lohengrin, Nieder-Olm")
      leiningen.bindPopup("Stamm Leiningen, Bobenheim am Berg")
      parzival.bindPopup("Siedlung Parzival, Harxheim")
      cassiopeia.bindPopup("Stamm Cassiopeia, Wörrstadt")
      lutra.bindPopup("Stamm Lutra, Kaiserslautern")
      dibo.bindPopup("Stamm Dietrich Bonhoeffer, Bockenheim")
      sindilo.bindPopup("Stamm Sindilo, Hochspeyer")
      nibelungen.bindPopup("Stamm Nibelungen, Worms")
      mainzDraisLerchenberg.bindPopup("Siedlung Mainz Drais-Lerchenberg")
    }
  }

  render() {
    return (
      <Fragment>
        <MapDiv id="map" />
        <div style={{ height: "50px" }} />
      </Fragment>
    )
  }
}
